import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import * as Icon from "react-bootstrap-icons"
import { useDispatch, useSelector } from 'react-redux';
import { update_filter } from '../../../store/slices/filter.slice';


export default function Filter(props) {
    const filter = useSelector((state) => state.filter)
    const dispatch = useDispatch()

    return (
        <Draggable key={props.filter} draggableId={props.filter} index={props.index}>
            {(provided) => (
                <div className="filter" id={props.filter.toLowerCase()} ref={provided.innerRef} {...provided.draggableProps}>
                    <div className="filter_inner" style={{ flex: 1 }} onClick={() => dispatch(update_filter({ value: props.filter }))}>
                        <div className="name"><span className="move_dots" {...provided.dragHandleProps}>⫶</span>{props.filter}</div> <div className="icon" style={{ color: filter.value === props.filter ? "black" : "" }}><Icon.FilterLeft /></div>
                    </div>
                    <div style={{ flex: props.flex - 1 }} />
                </div>
            )}
        </Draggable>
    );
}