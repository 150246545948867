import history from "./history";
import { Router, Switch, Route } from "react-router";

import Index from "./components/shortlist";
import Files from "./components/files/files";
import Landing from "./components/landing/landing";
import Registration from "./components/auth/registration/registration";
import CheckEmails from "./components/auth/verify/check_emails";
import Verification from "./components/auth/verify/verification";
import VerifyLogin from "./components/auth/verify/verify_login";
import Loader from "./components/tools/loader/loader"
import NotFound from "./components/error/not_found";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { hide } from "./store/slices/loading.slice";
import Account from "./components/account";
import agent from "./agent/agent";
import { update_user } from "./store/slices/user.slice";

export default function App() {
  const user = useSelector(state => state.user)
  const token = useSelector(state => state.token)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!user || !user.id) {
      SSO()
    }
  }, [token]) // eslint-disable-line react-hooks/exhaustive-deps

  async function SSO() {
    if (token.value) {
      let res = await agent.auth.current(token.value)
      dispatch(update_user({ ...res.user }))
      if (res.user.id && res.user.verified === false) {
        dispatch(hide())
        history.push("/verify")
      }
    }
    // else if(history.location !== "/") {
    //   dispatch(hide())
    //   history.push("/")
    // }
  }

  return (
    <div className="App">
      <Loader />
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/register" component={Registration} />
          <Route exact path="/files" component={Files} />
          <Route exact path="/shortlist" component={Index} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path={["/verify", "/login"]} component={CheckEmails} />
          <Route path="/verify/:id" component={Verification} />
          <Route path="/login/:key" component={VerifyLogin} />
          <Route path="/shortlist/:shortlist" component={Index} />
          <Route path="/" component={NotFound} />
        </Switch>
      </Router>
    </div>
  );
}