import { createSlice, current } from '@reduxjs/toolkit'
import socket from '../../agent/socket'
import { v4 as uuidv4 } from 'uuid';

export const shortlistSlice = createSlice({
    name: "shortlist",
    initialState: {
        id: "",
        owner: "",
        name: null,
        permission: "View",
        list: [],
        all: [],
        test: false
    },
    reducers: {
        add_property: (state, action) => {
            if (state.list.filter(e => e.address === action.payload.address).length === 0) {
                if ((state.test && state.list.length < 3) || !state.test) {
                    let key = uuidv4()
                    socket.emit("put_data", { id: state.id, properties: [...state.list, { key, ...action.payload }], owner: state.owner, permission: state.permission, name: state.name })
                    state.list = [...state.list, { key, ...action.payload }]
                }
            }
        },
        update_name: (state, action) => {
            state.name = action.payload
            socket.emit("update_name", { id: state.id, name: state.name })
        },
        remove_property: (state, action) => {
            state.list = state.list.filter(e => e.key !== action.payload.key)
            socket.emit("put_data", { id: state.id, properties: state.list, owner: state.owner, permission: state.permission, name: state.name })
        },
        add_comment: (state, action) => {
            if (!current(state.list).filter(e => e.key === action.payload.key)[0].comments.includes(action.payload.comment)) {
                state.list.filter(e => e.key === action.payload.key)[0].comments = [action.payload.comment, ...state.list.filter(e => e.key === action.payload.key)[0].comments]
                socket.emit("put_data", { id: state.id, properties: state.list, owner: state.owner, permission: state.permission, name: state.name })
            }
        },
        update_permission: (state, action) => {
            state.permission = action.payload
            socket.emit("put_permission", { id: state.id, permission: state.permission })
        },
        update_properties: (state, action) => {
            state.list = action.payload.properties
            state.id = action.payload.id
            state.owner = action.payload.owner
            state.name = action.payload.name
            state.permission = action.payload.permission
            state.test = action.payload.test || false
        },
        update_owner: (state, action) => {
            state.owner = action.payload
        },
        update_all_shortlists: (state, action) => {
            state.all = action.payload.map(e => {
                return e._id
            })
        },
        remove_test: (state) => {
            state.test = false
        },
        add_to_all: (state, action) => {
            state.all = [...state.all, action.payload]
        },
        remove_shortlist: (state) => {
            state.list = []
            state.id = ""
            state.owner = ""
            state.name = null
            state.permission = "View"
            state.test = false
        }
    }
})

export const { add_property, remove_property, update_name, update_owner, remove_test, add_comment, update_permission, update_properties, update_all_shortlists, add_to_all, remove_shortlist } = shortlistSlice.actions

export default shortlistSlice.reducer