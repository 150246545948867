import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../agent/agent';
import socket from '../../agent/socket';
import history from '../../history';
import { hide, show } from '../../store/slices/loading.slice';
import { remove_by_id, remove_by_user } from '../../store/slices/secondaries.slice';
import { add_to_all, update_all_shortlists, update_properties } from '../../store/slices/shortlist.slice';
import { remove_user } from '../../store/slices/user.slice';
import Modal from '../tools/modal/modal';
import toggle_modal from '../tools/modal/toggle_modal';
import * as Icon from "react-bootstrap-icons"
import { logout } from '../../store/slices/token.slice';

function Files(props) {
    const [shortlists, setShortlists] = useState([])
    const user = useSelector(state => state.user)
    const secondaries = useSelector(state => state.secondaries)
    const dispatch = useDispatch()
    const [modalInfo, setModalInfo] = useState({})

    useEffect(() => {
        if (user.id) {
            dispatch(remove_by_user(user.id))
        }
    }, [user]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dispatch(show("Loading files"))
        dispatch(update_properties({ id: "", properties: [] }))

        async function check_secondaries() {
            let res = await agent.shortlist.check_secondaries(secondaries.list.map(e => {
                return e.id
            }))
            if (res.success) {
                dispatch(remove_by_id(res.list.map(e => {
                    return { id: e }
                })))
            }
        }

        check_secondaries()

        socket.emit("fetch_shortlists", { id: user.id })
        socket.on("return_shortlists", shortlists => {
            setShortlists(shortlists)
            dispatch(update_all_shortlists(shortlists))
            dispatch(hide())
        })

        return () => {
            socket.off("return_shortlists")
        }
    }, [user.id]) // eslint-disable-line react-hooks/exhaustive-deps

    async function create_shortlist() {
        if (user.id) {
            let res = await agent.shortlist.create(user.id)
            if (res.success) {
                dispatch(add_to_all(res.list._id))
                socket.emit("fetch_shortlists", { id: user.id })
                history.push(`/shortlist/${res.list._id}`)
            }
        } else {
            history.push("/register")
        }
    }

    function open_modal(e, id) {
        e.stopPropagation()
        setModalInfo(shortlists.filter(e => e._id === id)[0])
        toggle_modal("file_modal")
    }

    function onModalClose() {
        setModalInfo({})
        toggle_modal("file_modal")
    }

    async function delete_shortlist(id) {
        let res = await agent.shortlist.delete(id)
        if (res.success) {
            onModalClose()
            setShortlists(shortlists.filter(e => e._id !== id))
        }
    }

    return (
        <div className='files'>
            {user.id ?
                <>
                    <button onClick={() => history.push("/account")}>
                        Account
                    </button>
                    <br />
                    <button onClick={create_shortlist}>
                        Create new shortlist
                    </button>
                    <br /><br />
                </>
                : null
            }
            My shortlists:
            {shortlists.length === 0 ? <div>No shortlists</div> : shortlists.map((e, n) => {
                return <div className='file' key={n} onClick={() => history.push("/shortlist/" + e._id)}>
                    {e.name || e._id}
                    <div className='x' onClick={(ev) => open_modal(ev, e._id)}>
                        <Icon.Trash />
                    </div>
                </div>
            })}
            {!user.id ?
                <div>
                    <button onClick={() => history.push("/shortlist")}>
                        Create test shortlist
                    </button>
                    <br />
                    <button onClick={() => history.push("/register")}>
                        Create account
                    </button>
                </div> : null}
            <br />
            My secondaries:
            {secondaries.list.length === 0 ? <div>No secondaries</div> : secondaries.list.map((e, n) => {
                console.log(e)
                return <div key={n} onClick={() => history.push("/shortlist/" + e.id)}>
                    {e.name || e.id} by {e.owner.name}
                </div>
            })}
            <br />
            {user.id ?
                <button onClick={() => {
                    dispatch(remove_user())
                    dispatch(logout())
                    history.push("/")
                }}>
                    Logout
                </button>
                : null}

            <Modal id="file_modal" x backgroundClose onModalClose={onModalClose}>
                <div className='heading'>
                    Delete {modalInfo.name || modalInfo._id}?
                </div>
                <div className='content' style={{ textAlign: "center" }}>
                    Are you sure you would like to delete this shortlist?
                    <div style={{ color: "red", fontWeight: "600" }}>Warning: This can't be undone</div>
                </div>
                <br />
                <div>
                    <button className='primary' onClick={() => delete_shortlist(modalInfo._id)}>
                        Delete
                    </button>
                    <br />
                    <button className='secondary' onClick={onModalClose}>
                        Cancel
                    </button>
                </div>
            </Modal>
        </div>
    );
}

export default Files;