import { createSlice, current } from '@reduxjs/toolkit'

export const userSlice = createSlice({
    name: "user",
    initialState: {},
    reducers: {
        update_user: (state, action) => {
            Object.entries(action.payload).map(e => {
                state[e[0]] = e[1]
                return true
            })
        },
        remove_user: (state) => {
            let current_state = current(state)
            Object.entries(current_state).map(e => {
                delete state[e[0]]
                return true
            })
        }
    }
})

export const { update_user, remove_user } = userSlice.actions

export default userSlice.reducer