import superagent from "superagent"

let url = process.env.REACT_APP_API_URL

const api = {
    get: (path, params) => {
        return superagent.get(url + path).query(params).then(res => { return res.body }).catch(err => console.log(err))
    },
    post: (path, body) => {
        return superagent.post(url + path).send(body).then(res => { return res.body }).catch(err => console.log(err))
    },
    delete: (path, body) => {
        return superagent.delete(url + path).send(body).then(res => { return res.body }).catch(err => console.log(err))
    },
    put: (path, body) => {
        return superagent.put(url + path).send(body).then(res => { return res.body }).catch(err => console.log(err))
    },
    patch: (path, body) => {
        return superagent.patch(url + path).send(body).then(res => { return res.body }).catch(err => console.log(err))
    }
}

let auth = {
    create: (first_name, last_name, email) => { return api.post("/user/create", { first_name, last_name, email }) },
    verify: {
        email: id => { return api.post("/user/verify", { id }) },
        login: key => { return api.post("/user/login/verify", { key }) }
    },
    current: token => { return api.post("/user/current", { token }) },
    login: email => { return api.post("/user/login", { email }) },
    delete: id => { return api.delete("/user/delete", { id }) }
}

let scraper = {
    scrape: url => { return api.post("/scrape", { url }) },
}

let shortlist = {
    create: user => { return api.post("/shortlist/create", { user }) },
    create_pre: (user, properties, name) => { return api.put("/shortlist/create", { user, properties, name }) },
    check_secondaries: secondaries => { return api.post("/shortlist/check_secondaries", { secondaries }) },
    update_user: (id, user) => { return api.post("/shortlist/update_user", { id, user }) },
    delete: id => { return api.delete("/shortlist/delete", { id }) }
}

let agent = {
    auth,
    scraper,
    shortlist
}

export default agent