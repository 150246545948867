import React from 'react';
import { useSelector } from 'react-redux';
import Property from './property';

export default function Properties(props) {
    const shortlist = useSelector(state => state.shortlist)
    const filter = useSelector(state => state.filter)

    return [...shortlist?.list].sort((a, b) => {
        let f = filter.value.toLowerCase()
        if (f === "location") {
            if (a.address > b.address) return 1
            else if (a.address < b.address) return -1
            else return 0
        } else if (f === "comments") {
            if (!a.comments[0] && b.comments[0]) return 1
            else if (!b.comments[0] && a.comments[0]) return -1
            else if (!b.comments[0] && !a.comments[0]) return 1
            if (a.comments[0].date > b.comments[0].date) return -1
            else if (a.comments[0].date < b.comments[0].date) return 1
            else return 0
        } else if (f === "garden" || f === "bedrooms" || f === "bathrooms") {
            if (a[f] > b[f]) return -1
            else if (a[f] < b[f]) return 1
            else return 0
        } else {
            if (a[f] > b[f]) return 1
            else if (a[f] < b[f]) return -1
            else return 0
        }
    }).map((e, n) => {
        console.log(e)
        return <Property id={n} e={e} />
    })
}