import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../agent/agent';
import history from '../../history';
import { hide, show } from '../../store/slices/loading.slice';
import { update_user } from '../../store/slices/user.slice';
const scrape = require("property_scraper")
// import { update_user } from '../../store/slices/user.slice';

export default function Landing(props) {
    const [email, setEmail] = useState("")
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const [error, setError] = useState("")

    useEffect(() => {
        console.log(scrape)
    }, [])

    useEffect(() => {
        async function SSO() {
            if (user.token) {
                let res = await agent.auth.current(user.token)
                if (res.success) {
                    if (res.user.verified) {
                        history.push("/files")
                    } else {
                        history.push("/verify")
                    }
                }
            }
        }

        SSO()
    }, [user])  // eslint-disable-line react-hooks/exhaustive-deps

    // function select_user(user) {
    //     dispatch(update_user({ first_name: user }))
    //     history.push(`/files`)
    // }

    async function login() {
        dispatch(show("Sending email"))
        let res = await agent.auth.login(email)
        if (res.success) {
            dispatch(update_user({ email: email }))
            dispatch(hide())
            history.push("/login")
        } else {
            setError(res.message)
            dispatch(hide())
        }
    }

    return (
        <div>
            Paste by Free.co.uk
            <br />
            <br/>
            Who's there?
            <br />

            <br />

            <button onClick={() => history.push("/register")}>
                New user
            </button>
            <br />
            <input type="text" placeholder="Email" value={email} onChange={e => {
                setError("")
                setEmail(e.currentTarget.value)
            }} />
            <button onClick={login}>
                Login
            </button>
            <br />
            <div className="error">{error}</div>
            <br />
            {!user.id ?
                <div>
                    Want to have a shot before registering?
                    <br />
                    <button onClick={() => history.push("/shortlist")}>
                        Create test shortlist
                    </button>
                </div>
                : null}
        </div>
    );
}