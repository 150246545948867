import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../agent/agent';
import history from "../../history"
import { remove_filter } from '../../store/slices/filter.slice';
import { remove_secondaries } from '../../store/slices/secondaries.slice';
import { remove_shortlist } from '../../store/slices/shortlist.slice';
import { logout } from '../../store/slices/token.slice';
import { remove_user } from '../../store/slices/user.slice';

export default function Account(props) {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    async function delete_account() {
        let res = await agent.auth.delete(user.id)
        console.log(res)
        if(res.success) {
            dispatch(remove_user())
            dispatch(logout())
            dispatch(remove_shortlist())
            dispatch(remove_secondaries())
            dispatch(remove_filter())
            history.push("/")
        }
    }

    return (
        <div>
            <div>{user.first_name} {user.last_name}</div>
            <div>{user.email}</div>
            <br/>
            <button onClick={delete_account}>Delete account</button>
            <br/>
            <button onClick={() => history.push("/files")}>Back</button>
        </div>
    );
}