import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../../agent/agent';
import history from '../../../history';
import { remove_user } from '../../../store/slices/user.slice';

export default function CheckEmails(props) {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        async function SSO() {
            if (user.token) {
                let res = await agent.auth.current(user.token)
                if (res.success) {
                    if (res.user.verified) {
                        history.push("/files")
                    }
                }
            }
        }

        SSO()
    }, [user.token])

    async function goBack() {
        if (history.location.pathname === "/login") {
            dispatch(remove_user())
            history.push("/")
        } else if (history.location.pathname === "/verify") {
            let res = await agent.auth.remove(user.id)
            console.log(res)
            if (res.success) {
                dispatch(remove_user())
                history.push("/register")
            }
        }
    }

    return (
        <div>
            Please check your emails to verify your account.
            <br /><br />
            Email sent to {user.email}.
            <br /><br />
            Wrong email?
            <br />
            <button onClick={goBack}>
                Go back
            </button>
        </div>
    );
}