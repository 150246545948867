import React from 'react';
import history from '../../history';

export default function NotFound(props) {
    return (
        <div>
            Page not found
            <br/>
            <button onClick={() => history.push("/")}>
                Back
            </button>
        </div>
    );
}