import React from 'react';
import { useSelector } from 'react-redux';
import PasteLink from './paste_link/paste_link';
import Properties from './properties/properties';

export default function Columns(props) {
    const short = useSelector(state => state.shortlist)
    const user = useSelector(state => state.user)

    function admin() {
        if (short?.owner?.id === user.id) return true
        else return false
    }

    function permission() {
        if (admin() || short.permission === "Edit") return true
        else return false
    }

    return (
        <div className="container">
            <div className="columns" style={{ width: short?.list?.length * 301 + 300 + "px" }}>
                <Properties id={props.id} />
                {permission() ?
                    <PasteLink />
                    : null
                }
            </div>
        </div>
    );
}