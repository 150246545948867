import storage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session'
import { combineReducers } from 'redux';
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';

import userSlice from './slices/user.slice';
import filterSlice from './slices/filter.slice';
import shortlistSlice from './slices/shortlist.slice';
import secondariesSlice from './slices/secondaries.slice';
import loadingSlice from './slices/loading.slice';
import tokenSlice from './slices/token.slice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ["filter", "secondaries"]
};

// const userConfig = {
//     key: 'user', 
//     storage:storageSession,
//     blacklist: ["user"]
// }

// const shortlistConfig = {
//     key: 'shortlist',
//     storage:storageSession,
//     blacklist: ["shortlist"]
// }

const reducers = combineReducers({
    // user: persistReducer(userConfig, userSlice),
    user: userSlice,
    filter: filterSlice,
    // shortlist: persistReducer(shortlistConfig, shortlistSlice),
    shortlist: shortlistSlice,
    secondaries: secondariesSlice,
    loading: loadingSlice,
    token: tokenSlice
});

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});