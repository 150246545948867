import commaNumber from 'comma-number';
import React from 'react';
import * as Icon from "react-bootstrap-icons"

export default function Marker(props) {
    return (
        <div>
            <div className='prop'>
                <div className='icon'>
                    <Icon.HouseFill />
                </div>

                <div className='prop_box'>
                    <div className='marker_box' onClick={() => window.open(props.e.url)}>
                        <img className='image' src={props.e?.images[0]} alt="Marker" />
                        <div className='price'>£{commaNumber(props.e.price[0].value)}</div>
                        <div className='title'>{props.e.title}</div>
                        <div className='title'>Bedrooms: <b>{props.e.bedrooms}</b>&emsp;Bathrooms: <b>{props.e.bathrooms || 0}</b></div>
                        <div className='title'>Garden: <b>{props.e.garden ? "Yes" : "No"}</b></div>
                    </div>
                    <div className='bottom_arrow' />
                </div>
            </div>
        </div>
    );
}