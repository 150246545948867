import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import agent from '../../../agent/agent';
import history from '../../../history';
import { hide, show } from '../../../store/slices/loading.slice';
import { update_user } from '../../../store/slices/user.slice';

export default function Registration(props) {
    const [first, setFirst] = useState(sessionStorage.getItem("register_first_name") || "")
    const [last, setLast] = useState(sessionStorage.getItem("register_last_name") || "")
    const [email, setEmail] = useState(sessionStorage.getItem("register_email") || "")
    const [error, setError] = useState("")
    const dispatch = useDispatch()

    async function create_account() {
        dispatch(show("Creating account"))
        let res = await agent.auth.create(first, last, email)
        if (res.success) {
            dispatch(update_user({ id: res.user.id, first_name: res.user.first_name, last_name: res.user.last_name, email: res.user.email, token: res.user.token, guest: false, verified: false }))
            dispatch(hide())
            history.push("/verify")
        } else {
            setError(res.message)
            dispatch(hide())
        }
    }

    return (
        <div>
            <input type="text" placeholder="First name" value={first} onChange={e => {
                try {
                    sessionStorage.setItem("register_first_name", e.currentTarget.value)
                } catch (err) {
                    console.log(err)
                }
                setFirst(e.currentTarget.value)
                setError("")
            }} />
            <br />
            <input type="text" placeholder="Last name" value={last} onChange={e => {
                try {
                    sessionStorage.setItem("register_last_name", e.currentTarget.value)
                } catch (err) {
                    console.log(err)
                }
                setLast(e.currentTarget.value)
                setError("")
            }} />
            <br />
            <input type="text" placeholder="Email" value={email} onChange={e => {
                try {
                    sessionStorage.setItem("register_email", e.currentTarget.value)
                } catch (err) {
                    console.log(err)
                }
                setEmail(e.currentTarget.value)
                setError("")
            }} />

            <br />
            <br />

            <button onClick={() => create_account()}>
                Go
            </button>
            <div className="error">
                {error}
            </div>
        </div>
    );
}