import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Filter from './filter';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { update_order } from '../../../store/slices/filter.slice';

export default function FixedColumn(props) {
    const filter = useSelector(state => state.filter)
    const dispatch = useDispatch()

    function handleOnDragEnd(result) {
        if (!result.destination) return;

        const items = Array.from(filter.order);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        dispatch(update_order(items))
    }

    return (
        <div className="fixed_column">
            <div className="contain">
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="filters">
                        {(provided) => (
                            <div className="filters" style={{ flex: 9.5 }} {...provided.droppableProps} ref={provided.innerRef}>
                                {[...filter.order].map((e, n) => {
                                    let flex = 1
                                    if (e === "Images") flex = 3.5
                                    else if (e === "Location") flex = 2
                                    else if (e === "Comments") flex = 4
                                    return <Filter filter={e} flex={flex} index={n} />
                                })}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                {/* <button className="add" onClick={() => {
                    document.getElementById("add_new").scrollIntoView({ behavior: "smooth" })
                }}>
                    Add new
                </button> */}
            </div>
        </div>
    );
}