import { createSlice } from '@reduxjs/toolkit'
import socket from '../../agent/socket'

export const secondariesSlice = createSlice({
    name: "secondaries",
    initialState: {
        list: []
    },
    reducers: {
        add_secondary: (state, action) => {
            let secondary = {
                id: action.payload.id,
                name: action.payload.name,
                owner: {
                    id: action.payload.owner.id,
                    name: action.payload.owner.name
                }
            }
            if (state.list.filter(e => e.id === action.payload.id).length > 0) {
                state.list.splice(state.list.indexOf(state.list.filter(e => e.id === action.payload.id)[0]), 1, secondary)
            } else {
                socket.emit("put_secondary", { ...secondary })
                state.list = [...state.list, { ...secondary }]
            }
        },
        update_all_secondaries: (state, action) => {
            state.list = action.payload
        },
        remove_by_id: (state, action) => {
            if (action.payload.length > 0) {
                for (let i = 0, size = action.payload; i < size; i++) {
                    state.list(state.list.indexOf(state.list.filter(e => e.id === action.payload[i])[0]), 1)
                }
            } else {
                state.list = []
            }
        },
        remove_by_user: (state, action) => {
            state.list = state.list.filter(e => e.owner.id !== action.payload)
        },
        remove_secondaries: (state) => {
            state.list = []
        }
    }
})

export const { add_secondary, update_all_secondaries, remove_by_id, remove_by_user, remove_secondaries } = secondariesSlice.actions

export default secondariesSlice.reducer