import { createSlice } from '@reduxjs/toolkit'

export const loadingSlice = createSlice({
    name: "loading",
    initialState: {
        display: false,
        message: null
    },
    reducers: {
        show: (state, action) => {
            state.display = true
            if (action.payload) {
                state.message = action.payload
            } else {
                state.message = null
            }
        },
        hide: (state) => {
            state.display = false
            state.message = null
        }
    }
})

export const { show, hide } = loadingSlice.actions

export default loadingSlice.reducer