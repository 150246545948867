import React from 'react';
import commaNumber from "comma-number"
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { add_comment, remove_property } from '../../../../store/slices/shortlist.slice';
import settings from './settings';
import sold from "../../../../assets/img/sold.png"
require("datejs")

export default function Property(props) {
    const filter = useSelector(state => state.filter)
    const user = useSelector(state => state.user)
    const short = useSelector(state => state.shortlist)
    const [comment, setComment] = useState("")

    const dispatch = useDispatch()

    function send_comment() {
        console.log(props.e.title)
        dispatch(add_comment({
            key: props.e.key, comment: {
                name: user.first_name + " " + user.last_name,
                date: new Date().toISOString(),
                comment: comment
            }
        }))
        setComment("")
    }

    function remove() {
        dispatch(remove_property({ key: props.e.key }))
    }

    function admin() {
        if (short?.owner?.id === user.id) return true
        else return false
    }

    function permission() {
        if (admin() || short.permission === "Edit") return true
        else return false
    }

    return (
        <div key={"property_" + props.id} className="column prop" draggable>
            <div className="property">
                {permission() ?
                    <div className="x" onClick={remove}>
                        ×
                    </div>
                    : null
                }
                {props.e.sold ? <img className='sold_sign' src={sold} alt="Sold sign" /> : null}
                {[...filter.order].map((e, n) => {
                    if (e === "Title") {
                        return <div className="title" onClick={() => window.open(props.e.url)}>
                            {props.e.title}
                        </div>
                    } else if (e === "Images") {
                        return <div className="images">
                            <Slider className="slideshow" {...settings}>
                                {props.e?.images?.map((e, n) => {
                                    return <img key={n} src={e} className="img" alt="None" />
                                })}
                            </Slider>
                        </div>
                    } else if (e === "Price") {
                        let prices = props.e.price.slice().sort((a, b) => { return new Date(b.date) - new Date(a.date) })
                        let last_month = new Date.today().addMonths(-1)
                        console.log(Date.compare(last_month, new Date(prices[0].date)), prices[0].date)
                        // prices[0].date
                        if (Date.compare(last_month, new Date(prices[0].date)) === -1) {
                            return <div className="price" style={{ fontWeight: prices[1] ? "600" : "400", color: prices[1] ? prices[0].value > prices[1].value ? "red" : "green" : "" }}>
                                <div className="previous" style={{ display: prices[1] ? "inline-block" : "none" }}>{prices[1] ? "£" + commaNumber(prices[1].value) : null}</div>
                                £{commaNumber(prices[0].value)}
                            </div>
                        } else {
                            return <div className="price">
                                £{commaNumber(prices[0].value)}
                            </div>
                        }
                    } else if (e === "Bedrooms") {
                        return <div className="bedrooms">
                            {props.e.bedrooms}
                        </div>
                    } else if (e === "Bathrooms") {
                        return <div className="bathrooms">
                            {props.e.bathrooms || "-"}
                        </div>
                    } else if (e === "Location") {
                        return <div className="location">
                            <div style={{ height: "20px" }} />
                            {props.e.address.replace(/,/g, "\n")}
                        </div>
                    } else if (e === "Garden") {
                        return <div className="garden">
                            {props.e.garden ? "Yes" : "No"}
                        </div>
                    } else if (e === "Comments") {
                        return <div className="comments">
                            <div className="contain">
                                <div className="comment_input_wrap">
                                    <input type="text" value={comment} onKeyPress={e => {
                                        if (e.code === "Enter") {
                                            send_comment()
                                        }
                                    }} onChange={e => setComment(e.currentTarget.value)} placeholder="Start typing a comment" className="comment_input" />
                                </div>
                                <div className="mapped">
                                    {[...props.e.comments].sort((a, b) => {
                                        return new Date(b.date) - new Date(a.date)
                                    }).map((e, n) => {
                                        return <div key={n} className="comment">
                                            <div className="top">
                                                <span className="name">{e.name === user.first_name + " " + user.last_name ? "Me" : e.name}:</span>
                                                <span className="date">{Date.parse(e.date).toString("dS MMM yyyy, HH:mm")}</span>
                                            </div>
                                            <div className="bottom">
                                                {e.comment}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                    return null
                })}
            </div>
        </div>
    );
}