import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../../agent/agent';
import history from '../../../history';
import { hide, show } from '../../../store/slices/loading.slice';
import { remove_test, update_properties } from '../../../store/slices/shortlist.slice';
import { login } from '../../../store/slices/token.slice';
import { update_user } from '../../../store/slices/user.slice';

export default function VerifyLogin(props) {
    const shortlist = useSelector(state => state.shortlist)
    const dispatch = useDispatch()
    const [error, setError] = useState()

    useEffect(() => {
        async function verify_login() {
            let res = await agent.auth.verify.login(props.match.params.key)
            console.log(res)
            if (res.success) {
                if (shortlist.list.length !== 0 && shortlist.test === true) {
                    dispatch(show("Saving shortlist"))
                    let save = await agent.shortlist.create_pre(res.user.id, shortlist.list, shortlist.name)
                    if (save.success) {
                        dispatch(update_properties({
                            id: save.list._id,
                            name: save.list.name,
                            list: save.list.properties,
                            owner: {
                                id: res.user._id,
                                name: res.user.first_name + " " + res.user.last_name
                            },
                            permission: save.list.permission,
                            test: false
                        }))

                        try {
                            sessionStorage.removeItem("register_first_name")
                            sessionStorage.removeItem("register_last_name")
                            sessionStorage.removeItem("register_email")
                        } catch (err) {
                            console.log(err)
                        }
                        dispatch(update_user({ ...res.user, guest: false, verified: true }))
                        dispatch(login(res.user.token))
                        dispatch(remove_test())
                        dispatch(hide())
                        history.push("/files")
                    }
                } else {
                    try {
                        sessionStorage.removeItem("register_first_name")
                        sessionStorage.removeItem("register_last_name")
                        sessionStorage.removeItem("register_email")
                    } catch (err) {
                        console.log(err)
                    }
                    dispatch(update_user({ ...res.user, guest: false, verified: true }))
                    dispatch(login(res.user.token))
                    dispatch(remove_test())
                    dispatch(hide())
                    history.push("/files")
                }
            } else {
                setError(res.message)
                history.push("/")
            }
        }

        verify_login()
    }, [props.match.params.key]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {error || "Verifying..."}
        </div>
    );
}