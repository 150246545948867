import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import agent from '../../../../agent/agent';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { add_property } from '../../../../store/slices/shortlist.slice';
import history from '../../../../history';
import toggle_modal from '../../../tools/modal/toggle_modal';

export default function PasteLink(props) {
    const [link, setLink] = useState("")
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const shortlist = useSelector(state => state.shortlist)

    const dispatch = useDispatch()

    useEffect(() => {
        console.log(shortlist)
        if (shortlist.test && shortlist.list.length >= 3) {
            toggle_modal("save_modal")
        } else {
            if (link) {
                setError("")
            }
            if (link && validator.isURL(link)) {
                setLoading(true)

                agent.scraper.scrape(link).then(res => {
                    if (res.success === true) {
                        dispatch(add_property(res.property))
                        setLink("")
                        setLoading(false)
                        history.push(window.location.pathname)
                    } else {
                        console.log(res)
                        setError(res.message)
                        setLink("")
                        setLoading(false)
                    }
                }).catch(err => console.log(err))

            } else if (link && !validator.isURL(link)) {
                setError("Invalid URL")
            }
        }
    }, [link]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        console.log(error)
    }, [error])

    return (
        <div className="column end" id="add_new">
            <div className="paste">
                <div className="header">
                    Paste link
                </div>
                <div className="content">
                    {/* Add a new property to your shortlist by pasting it's URL */}
                    Enter a URL below to add a column
                </div>
                <input id="add_input" value={link} onChange={e => setLink(e.currentTarget.value)} type="text" placeholder="Property URL" className="input" />
                <div className="error">
                    {error}
                </div>
            </div>
            {
                loading
                    ?
                    <div className="loading">
                        <div className="spinner" />
                    </div>
                    :
                    null
            }
        </div>
    );
}