import React from 'react';
import { useSelector } from 'react-redux';

export default function Loader(props) {
    const loading = useSelector(state => state.loading)

    return (
        <div className="loader" style={{display: loading.display ? "block" : "none"}}>
            <div className="icon">
                <div className="spinner" />
                <div className="message">
                    {loading.message}
                </div>
            </div>
        </div>
    );
}