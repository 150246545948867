import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import socket from '../../agent/socket';
import { add_secondary } from '../../store/slices/secondaries.slice';
import { update_name, update_permission, update_properties } from '../../store/slices/shortlist.slice';
import { update_user } from '../../store/slices/user.slice';
import generate_name from '../tools/generate_name';
import Columns from './columns';
import FixedColumn from './fixed_column';
import Loader from "../tools/loader/loader"
import { hide, show } from '../../store/slices/loading.slice';
import * as Icon from "react-bootstrap-icons"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import CopyToClipboard from 'react-copy-to-clipboard';
import history from '../../history';
import Modal from '../tools/modal/modal';
import Maps from '../maps/maps';

export default function Index(props) {
    const user = useSelector(state => state.user)
    const short = useSelector(state => state.shortlist)
    const [page, setPage] = useState("shortlist")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(show("Loading shortlist"))
        if (history.location.pathname === "/shortlist") {
            let first_name = user.first_name
            let last_name = user.last_name
            console.log(isEmpty(user), user)
            if (isEmpty(user)) {
                [first_name, last_name] = generate_name()
                dispatch(update_user({ first_name, last_name, guest: true }))
            }
            if (!short.owner) {
                dispatch(update_properties({ properties: [], id: null, owner: { id: null, name: first_name + " " + last_name }, name: "", permission: "Edit", test: true }))
            }
            dispatch(hide())
        } else {
            if (isEmpty(user)) {
                let [first_name, last_name] = generate_name()
                dispatch(update_user({ first_name, last_name, guest: true }))
            }

            if (props.match.params.shortlist) {
                socket.emit("grab_data", { id: props.match.params.shortlist })
                socket.on(props.match.params.shortlist, shortlist => {
                    if (shortlist) {
                        dispatch(update_properties({ properties: shortlist.properties, id: shortlist.id || shortlist._id, owner: shortlist.owner, name: shortlist.name, permission: shortlist.permission }))
                        if (!props.test) {
                            dispatch(add_secondary({ id: props.match.params.shortlist, name: shortlist.name, owner: shortlist.owner, permission: shortlist.permission }))
                        }
                        dispatch(hide())
                    } else {
                        dispatch(hide())
                        history.push("/404")
                    }
                })
            }

            return () => {
                socket.off(props.match.params.shortlist)
            }
        }
    }, [props.match.params.shortlist]) // eslint-disable-line react-hooks/exhaustive-deps

    function isEmpty(obj) {
        let { _persist, ...all } = obj
        return Object.keys(all).length === 0;
    }

    function change_name(name) {
        if (name !== null) dispatch(update_name(name))
    }

    function admin() {
        if (short?.owner?.id === user.id) return true
        else return false
    }

    function permission() {
        if (admin() || short.permission === "Edit") return true
        else return false
    }

    function test() {
        if (short.test) return true
        else return false
    }

    return (
        <div>
            <Loader />
            <NotificationContainer />
            <div className="shortlist_heading">
                <div className="header">
                    <div className="back_arrow" onClick={() => {
                        test() ?
                            history.push("/")
                            : history.push("/files")
                    }
                    }>
                        <Icon.ArrowLeftShort />
                    </div>
                    <input readOnly={!permission()} value={short.name} id="document_name" className="document_name" placeholder="Untitled document" onChange={e => change_name(e.currentTarget.value)} />
                    <div className="owner"><span className="by">by</span> {short?.owner?.name}</div>

                    {short.list.length > 0 ?
                        <div className='map_icon' onClick={page === "shortlist" ? () => setPage("map") : () => setPage("shortlist")}>
                            {page === "shortlist" ?
                                <Icon.PinMapFill />
                                : null}

                            {page === "map" ?
                                <Icon.FileEarmarkText />
                                : null}
                        </div>
                        : null}

                    {admin() ?
                        <div className="share_icon">
                            <div className="dropdown">
                                People with the link can
                                <select onChange={e => {
                                    dispatch(update_permission(e.currentTarget.value))
                                }}>
                                    <option value="View" selected={short.permission !== "Edit"}>View</option>
                                    <option value="Edit" selected={short.permission === "Edit"}>Edit</option>
                                </select>
                            </div>
                            <CopyToClipboard text={window.location.href} onCopy={() => {
                                console.log("Click")
                                NotificationManager.success("Copied to clipboard");
                            }}>
                                <Icon.Link45deg />
                            </CopyToClipboard>
                        </div>
                        : null
                    }

                    {test() ?
                        <div className='register_to_save'>
                            <div className='link' onClick={() => history.push("/register")}>Register an account</div> to save this shortlist
                        </div>
                        : null
                    }
                </div>
            </div>

            {page === "map" ?
                <Maps />
                : null}

            {page === "shortlist" ?
                <>
                    <FixedColumn />
                    <Columns id={props.test ? null : props.match.params.shortlist} />
                </>
                : null}

            <Modal id="save_modal">
                <div className='heading'>Thanks for trialing Paste!</div>
                <div className='content'>
                    To continue adding properties to this shortlist, please register an account.
                    <br /><br />
                    Why create an account?
                    <ul>
                        <li>Create multiple shortlists, with as many properties as you want</li>
                        <li>Share and collaborate with family and friends</li>
                        <li>Receive email notifications when the price of a property changes, or it is sold</li>
                    </ul>
                </div>
                <br />
                <button className='primary' onClick={() => history.push("/register")}>
                    Register
                </button>
            </Modal>
        </div>
    );
}