import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../../agent/agent';
import history from '../../../history';
import { hide, show } from '../../../store/slices/loading.slice';
import { remove_test, update_properties } from '../../../store/slices/shortlist.slice';
import { login } from '../../../store/slices/token.slice';
import { update_user } from '../../../store/slices/user.slice';

export default function Verification(props) {
    const shortlist = useSelector(state => state.shortlist)
    const dispatch = useDispatch()

    useEffect(() => {
        async function check_id() {
            dispatch(show("Verifying"))
            let res = await agent.auth.verify.email(props.match.params.id)
            console.log(res)
            if (res.success) {
                if (shortlist.list.length !== 0 && shortlist.test === true) {
                    dispatch(show("Saving shortlist"))
                    let save = await agent.shortlist.create_pre(res.user.id, shortlist.list, shortlist.name)
                    if (save.success) {
                        dispatch(update_properties({
                            id: save.list._id,
                            name: save.list.name,
                            list: save.list.properties,
                            owner: {
                                id: res.user._id,
                                name: res.user.first_name + " " + res.user.last_name
                            },
                            permission: save.list.permission,
                            test: false
                        }))

                        try {
                            sessionStorage.removeItem("register_first_name")
                            sessionStorage.removeItem("register_last_name")
                            sessionStorage.removeItem("register_email")
                        } catch (err) {
                            console.log(err)
                        }
                        dispatch(update_user({ id: props.match.params.id, first_name: res.user.first_name, last_name: res.user.last_name, email: res.user.email, guest: false, verified: true }))
                        dispatch(login(res.user.token))
                        dispatch(remove_test())
                        dispatch(hide())
                        history.push("/files")
                    }
                } else {
                    try {
                        sessionStorage.removeItem("register_first_name")
                        sessionStorage.removeItem("register_last_name")
                        sessionStorage.removeItem("register_email")
                    } catch (err) {
                        console.log(err)
                    }
                    dispatch(update_user({ id: props.match.params.id, first_name: res.user.first_name, last_name: res.user.last_name, email: res.user.email, guest: false, verified: true }))
                    dispatch(login(res.user.token))
                    dispatch(remove_test())
                    dispatch(hide())
                    history.push("/files")
                }
            }
        }

        check_id()
    }, [props.match.params.id]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            Verifying...
        </div>
    );
}