import { createSlice } from '@reduxjs/toolkit'

export const filterSlice = createSlice({
    name: "filter",
    initialState: {
        value: "Title",
        order: [
            "Title",
            "Images",
            "Price",
            "Bedrooms",
            "Bathrooms",
            "Location",
            "Garden",
            "Comments"
        ]
    },
    reducers: {
        update_filter: (state, action) => {
            state.value = action.payload.value
        },
        update_order: (state, action) => {
            state.order = action.payload
        },
        remove_filter: (state) => {
            state.value = "Title"
            state.order = [
                "Title",
                "Images",
                "Price",
                "Bedrooms",
                "Bathrooms",
                "Location",
                "Garden",
                "Comments"
            ]
        }
    }
})

export const { update_filter, update_order, remove_filter } = filterSlice.actions

export default filterSlice.reducer