import { createSlice } from '@reduxjs/toolkit'

export const tokenSlice = createSlice({
    name: "token",
    initialState: {
        value: null
    },
    reducers: {
        login: (state, action) => {
            console.log(action.payload)
            state.value = action.payload
        },
        logout: (state) => {
            state.value = null
        }
    }
})

export const { login, logout } = tokenSlice.actions

export default tokenSlice.reducer